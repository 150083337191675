@each $color, $value in $theme-colors {
  .fill-#{$color} {
    fill: $value;
  }
}

// Generate spacing utilities
$sides: (
  "t": "top",
  "r": "right",
  "b": "bottom",
  "l": "left"
);
$spaces: 0 5 10 15 20 25 30 35 40 45 50 60 70 80 90 100;
$remSpaces: (
  "0": 0,
  "0-25": 0.25,
  "0-5": 0.5,
  "0-75": 0.75,
  "1": 1,
  "1-25": 1.25,
  "1-5": 1.5,
  "1-75": 1.75,
  "2": 2,
  "2-25": 2.25,
  "2-5": 2.5,
  "2-75": 2.75,
  "3": 3,
  "3-5": 3.5,
  "4": 4,
  "4-5": 4.5,
  "5": 5
);
$breakpoints: "sm" "md" "lg" "xl";
$fontVariants: (
  "small": 0.75,
  "body2": 0.875,
  "body1": 1,
  "subtitle": 1.125,
  "emphasized": 1
);
$lineHeights: (
  "0": 0,
  "0-25": 0.25,
  "0-5": 0.5,
  "0-75": 0.75,
  "0-875": 0.875,
  "1": 1,
  "1-25": 1.25,
  "1-5": 1.5,
  "1-75": 1.75,
  "2": 2
);

@each $lineHeightName, $lineHeight in $lineHeights {
  .lh-#{$lineHeightName}-r {
    line-height: #{$lineHeight}rem !important;
  }
}

@each $fontVariantName, $fontVariant in $fontVariants {
  .fz-#{$fontVariantName} {
    font-size: #{$fontVariant}rem !important;
    @if $fontVariantName == emphasized {
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: 700;
      font-family: $font-family-sans-serif;
    }
  }
}

@each $space in $spaces {
  .m-#{$space} {
    margin: #{$space}px !important;
  }
  .p-#{$space} {
    padding: #{$space}px !important;
  }
}

@each $sideAbr, $sideName in $sides {
  @each $space in $spaces {
    .m#{$sideAbr}-#{$space} {
      margin-#{$sideName}: #{$space}px !important;
    }
    .p#{$sideAbr}-#{$space} {
      padding-#{$sideName}: #{$space}px !important;
    }
  }
}

@each $breakpoint in $breakpoints {
  @each $sideAbr, $sideName in $sides {
    @each $space in $spaces {
      .m#{$sideAbr}-#{$breakpoint}-#{$space} {
        @include media-breakpoint-up(#{$breakpoint}) {
          margin-#{$sideName}: #{$space}px !important;
        }
      }
      .p#{$sideAbr}-#{$breakpoint}-#{$space} {
        @include media-breakpoint-up(#{$breakpoint}) {
          padding-#{$sideName}: #{$space}px !important;
        }
      }
    }
  }
}

// Spaces in rem
@each $remSpaceName, $remSpace in $remSpaces {
  .m-#{$remSpaceName}-r {
    margin: #{$remSpace}rem !important;
  }
  .p-#{$remSpaceName}-r {
    padding: #{$remSpace}rem !important;
  }
}

@each $sideAbr, $sideName in $sides {
  @each $remSpaceName, $remSpace in $remSpaces {
    .m#{$sideAbr}-#{$remSpaceName}-r {
      margin-#{$sideName}: #{$remSpace}rem !important;
    }
    .p#{$sideAbr}-#{$remSpaceName}-r {
      padding-#{$sideName}: #{$remSpace}rem !important;
    }
  }
}

@each $breakpoint in $breakpoints {
  @each $sideAbr, $sideName in $sides {
    @each $remSpaceName, $remSpace in $remSpaces {
      .m#{$sideAbr}-#{$breakpoint}-#{$remSpaceName}-r {
        @include media-breakpoint-up(#{$breakpoint}) {
          margin-#{$sideName}: #{$remSpace}rem !important;
        }
      }
      .p#{$sideAbr}-#{$breakpoint}-#{$remSpaceName}-r {
        @include media-breakpoint-up(#{$breakpoint}) {
          padding-#{$sideName}: #{$remSpace}rem !important;
        }
      }
    }
  }
}

.ov-hidden {
  overflow: hidden;
}

// Icons
.icon-24 {
  height: 1.5rem;
  width: 1.5rem;
}

.icon-16 {
  height: 1rem;
  width: 1rem;
}

.icon-32 {
  height: 2rem;
  width: 2rem;
}

// Backgrounds
.bg-primary {
  background-color: $primary;
}

.bg-secondary {
  background-color: $secondary;
}

.bg-light {
  background-color: $light;
}

// Shadows
.shdow-none {
  box-shadow: none;
}

.shdow-th {
  box-shadow: $thin-shadow;
}

.shdow-sm {
  box-shadow: $small-shadow;
}

.shadow-md {
  box-shadow: $mid-shadow;
}

.shadow-lg {
  box-shadow: $big-shadow;
}
