body.modal-open .layout {
  filter: blur(6px);
}

.opacity-6 {
  opacity: 0.6;
}
.opacity-5 {
  opacity: 0.5;
}

.card {
  border: none;
  .card-header {
    &:first-child {
      border-radius: calc(#{$card-border-radius} - 1px) calc(#{$card-border-radius} - 1px) 0 0;
    }
  }
  box-shadow: $thin-shadow;
}

.btn {
  display: inline-block;
  text-transform: uppercase;
  white-space: nowrap;
  padding: 1.25rem 3em;
  background-position: center center;
  background-repeat: repeat;
  overflow: hidden;
  background-color: $primary;
  background: linear-gradient(to top,$secondary 50%,$primary 50%) no-repeat scroll right top / 100% 210% $secondary !important;
  color: #fff;
  box-sizing: border-box;
  transition: all 0.3s ease;
  white-space: nowrap;
  height: auto;
  border-width: 0 !important;
  border-radius: 3rem;
  font-size: 13px;
  font-weight: 600;
  font-style: normal;
  line-height: 1rem;
  text-decoration: none;
  letter-spacing: 0.1em;
  outline: none;;

  &:hover {
    background-position: right bottom !important;
    color: $white;
  }

  &-light {
    background-color: $white;
    background: linear-gradient(to top,$primary 50%,$white 50%) no-repeat scroll right top / 100% 210% $primary;
    border-radius: 3em;
    letter-spacing: 1px;
    font-size: 13px;
    line-height: 1rem;
    font-family: $headings-font-family;
    font-weight: 500;
    box-shadow: $thin-shadow;
    transition: all 0.3s ease;
    border: none;
    color: $primary;

    &:hover {
      background-position: right bottom !important;
      color: $white;
    }
  }

  &-link {
    color:$white;
    &:hover {
      color: $white;
      text-decoration: none;
    }
  }

  &-plain {
    background-color: transparent !important;
    background: none !important;
    color: $body-color;
    padding: 0;
    outline: none;
    box-shadow: none;

    &:hover {
      color: $secondary;
      outline: none;
      box-shadow: none;
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  &-small {
    padding: 1em 2em;
  }
}

.table {
  th,
  td {
    font-size: 0.875rem;
    vertical-align: middle;
    &:first-of-type {
      padding-left: 0;
    }
    &:last-of-type {
      text-align: right;
      padding-right: 0;
    }
  }
  th {
    font-size: $table-head-font-size;
    font-weight: 400;
    padding-bottom: 0.875rem;
  }
  td {
    font-size: $table-font-size;
    border-top: $table-border-width solid $table-border-color;
    border-bottom: $table-border-width solid $table-border-color;
  }
  .trim {
    max-width: 10rem;
    @include media-breakpoint-up(sm) {
      max-width: 14rem;
    }
    @include media-breakpoint-up(md) {
      max-width: 30rem;
    }
    @include media-breakpoint-up(xl) {
      max-width: 42rem;
    }
  }
  .dropdown {
    position: unset !important;
    .dropdown-menu {
      box-shadow: $dropdown-box-shadow;
    }
  }
}

label {
  @include font-size(80%);
}

.fa-spin {
  animation-duration: 0.5s;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1300px;
  }
}

.form-check-input {
  margin-top: 0.15rem;
}

.tab {
  font-family: $headings-font-family;
  font-size: $h6-font-size;
  font-weight: $font-weight-light;
  line-height: 1.2;
  color: $muted;
  cursor: pointer;
  outline: none;
  border: none;
  background-color: transparent;

  &.active {
    text-decoration: none;
    font-weight: $headings-font-weight;
    color: $body-color;
    outline: none
  }
}

.transactions-table {
  tr {
    td {
      padding: 1rem !important;
    }
  }
}