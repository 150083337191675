.home {
  &-inner {
    width: 100%;
    height: 80%;
    background: $white;
    position: relative;
    @include media-breakpoint-up(md) {
      width: 80%;
      height: 100%;
    }
    @include media-breakpoint-up(lg) {
      width: 60%;
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 100%;
      height: 100%;
      width: 100%;
      background: transparent url(./../../images/bottom-bg.png) no-repeat center bottom;
      background-size: contain;
      @include media-breakpoint-up(md) {
        top: 0;
        bottom: auto;
        left: auto;
        right: 100%;
        background: transparent url(./../../images/bottom-bg-vertical.png) no-repeat center right;
        background-size: contain;
      }
    }
  }

  &-wrapper {
    height: 100vh;
    background: transparent url(./../../images/home-bg.jpg) no-repeat center center;
    background-size: cover;
  }

  .login-container {
    font-size: 0.8125rem;
    max-width: 33.625rem;

    .btn {
      min-width: 6.875rem;
    }
    .logo {
      height: 8rem;
      width: auto;
    }
  }
}

@media (max-width: 560px) {
  .home {
    .login-container {
      .card-body {
        div {
          &:last-child {
            display: flex;
            flex-direction: column;
            align-items: center;
            button {
              margin: 16px 0;
            }
          }
        }
      }
    }
  }
}
