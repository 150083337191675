@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

$spacer-val: $spacer * 1.7;
// SPACING
$spacers: (
  spacer: (
    $spacer-val,
  ),
);

// Shadows
$thin-shadow: 0 2px 5px 0 rgba(61, 65, 68, 0.1);
$small-shadow: 0 1px 3px rgba(0, 0, 0, 0.1) !default;
$mid-shadow: 0 17px 27px rgba(0, 0, 0, 0.22) !default;
$big-shadow: 0 5px 10px rgba(0, 0, 0, 0.08),
  0 34px 73px -15px rgba(0, 0, 0, 0.25) !default;


// Typography
$fz-body: 16px;
$fz-smaller: 0.875rem;
$fz-small: 0.75rem;
$fz-tiny: 0.625rem;
